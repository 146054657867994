.customer-data-bottle-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c4c4c4;
}
.customer-data-bottle-head p {
  margin: 0;
  padding: 10px;
}
.customer-data-bottle-head p {
  width: 15%;
}
.customer-data-bottle-head p:last-child {
  width: 20%;
}
.customer-data-bottle-head p:first-child {
  width: 20%;
}
.customer-data-bottle-head p:nth-child(2) {
  width: 35%;
}
.empty-bottle-input {
  width: 100%;
  border: 1px solid #000;
  padding: 7px;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
}

/* Receipt Add*/
.MuiAutocomplete-input {
  padding: 4px !important;
  font-size: 14px !important;
}
.receiptCount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  padding: 2px;
}
.receiptCount p {
  margin: 0;
}
.receiptColumn {
  width: 50px;
}
.receiptDebitCredit {
  width: 150px;
}
.customerName {
  width: 40%;
}
.receiptPrice {
  width: 200px;
}
.SearchInputBar input {
  padding: 10px 14px !important;
}
.MuiTableCell-root {
  padding: 5px 10px !important;
}
.MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}


/* Bill Table Details */
.BillTableDetails{
  display: block;
  width: 100%;
  border-collapse: collapse;
  padding-top: 20px;
}
.BillTableDetails th{
  border: 1px solid #000;
  font-size: 12px;
}
.BillTableDetails td{
  border: 1px solid #000;
  font-size: 12px;
}
.BillTableDetails .inputCheckbox{
  width: 36px;
  text-align: center;
}
.BillTableDetails .BillCustomerName{
    width: 30%;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
}
.BillTableDetails .BillTotalBottle{
  width: 8%;
  text-align: center;
}
.updatedDataTable{
  border: 1px solid #000;
  display: block;
  width: 100%;
}
.updatedDataTable th,
.updatedDataTable td{
  border: 1px solid #000;
}
.billTableSection {
    border-collapse: collapse;
    max-width: 320px;
    width: 100%;
}
.billTableSection td{
  border: 1px solid #000;
}
.billPrintTitle{
    text-align: center;
    font-size: 15px;
    padding: 10px;
    font-weight: bold;
}
.billPrintAddressTitle{
  text-align: center;
}
.amountSection{
      text-align: right;
    font-weight: bold;
    font-size: 14px;
}
.ProductPrintList{
      font-size: 14px;
    font-weight: bold;
    padding: 4px 10px;
    text-align: center;
}
.BillContentFlex{
  display: flex;
  flex-wrap: wrap;
}
.BillContentFlex table{
  margin: 10px;
}
.CloseXButton{
  background: #675a50;
  width: 10px;
  height: 30px;
  padding: 0;
  min-width: 30px;
  line-height: normal;
  color: #fff;
}
.labelListSection h4{
  margin: 0;
  
}
.labelListSection{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.listSectionTab{
  display: flex;
  flex-wrap: wrap;
  background: #eee8ff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 10px;
}